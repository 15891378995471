<template>
  <div :class="{ 'animated-announce overflow-hidden': animatedAnnounce }">
    <Component
      :is="isSpecialProject ? 'a' : NuxtLink"
      :to="getUrl"
      :target="(isBlank || data?.outer_link) && '_blank'"
      v-bind="isSpecialProject && { href: getUrl }"
      class="group relative flex size-full text-white-100"
      :class="[
        { 'rounded-[12px]': !disablePreview },
        {
          'mb-6 rounded-none border-b border-new-card--border pb-3':
            disablePreview,
        },
      ]"
    >
      <MainPageCardsReworkedCommonCardImageBlock
        v-if="!disablePreview"
        v-bind="{
          data,
          disableLabel,
          priorityImage,
          position: positionImage,
        }"
        :aspect="aspect"
        :no-overlay="disableTitle"
        :animation="animation"
        class="size-full"
      >
        <div
          v-if="eventClose"
          class="absolute right-3 top-3 z-10 grid size-5 place-content-center rounded bg-white-100/20"
        >
          <SvgIcon
            name="close"
            class="size-4 text-white-100"
            @click.prevent.stop="emit('close')"
          ></SvgIcon>
        </div>
        <template #cover>
          <slot name="cover" />
        </template>
        <template #badge>
          <slot name="badge" />
        </template>
      </MainPageCardsReworkedCommonCardImageBlock>

      <div
        class="flex size-full flex-col justify-end font-roboto"
        :class="{ 'absolute p-[20px]': !disablePreview }"
      >
        <div
          v-if="slots.author || (!disableAuthor && getAuthorName)"
          class="z-30 flex items-center"
          :class="[
            { 'mb-[8px]': !disablePreview },
            { 'mb-[6px]': disablePreview },
          ]"
        >
          <div
            class="mr-[8px] size-[12px]"
            :class="getDataByModel?.labelBgColor"
          ></div>
          <p
            class="text-[14px] font-bold leading-[17px]"
            :class="{ 'text-new-test-black': disablePreview }"
          >
            <slot name="author">
              {{ getAuthorName }}
            </slot>
          </p>
        </div>

        <h2
          v-if="!disableTitle"
          class="text-clamp-3 z-30 font-medium"
          :class="[
            !isBookmark && titleTextSize,
            {
              'text-[20px] leading-[24px] text-new-test-black': disablePreview,
            },
            {
              'xl:text-[18px] xl:leading-[22px] 2xl:text-[18px] 2xl:leading-[22px]':
                isBookmark,
            },
            { ' mb-2.5': isMobile && isPagination },
          ]"
        >
          {{ getTitle }}
        </h2>

        <p
          v-if="!disableAnnounce && isDesktop"
          class="text-clamp-3 z-30 font-normal"
          :class="[
            {
              'mt-[10px] text-[14px] leading-[18px] text-new-card--title-black':
                disablePreview,
            },
            {
              'mt-[12px] text-[16px] 2xl:text-[20px] 2xl:leading-[28px]':
                !disablePreview,
            },
            {
              'animated-announce-hovered': animatedAnnounce,
            },
            { ' mb-2.5': isPagination },
          ]"
        >
          {{ shrinkText(truncTags(data.announce), isMobile ? 100 : 150) }}
        </p>
      </div>
    </Component>
  </div>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  disableAnnounce: { type: Boolean, default: false },
  disableAuthor: { type: Boolean, default: false },
  disableTitle: { type: Boolean, default: false },
  disableLabel: { type: Boolean, default: false },
  disablePreview: { type: Boolean, default: false },
  eventClose: { type: Boolean, default: false },
  isPagination: { type: Boolean, default: false },
  isTestResult: { type: Boolean, default: false },
  isSmallTitle: { type: Boolean, default: false },
  isBookmark: { type: Boolean, default: false },
  titleLength: { type: Number, default: 0 },
  priorityImage: { type: String, default: '' },
  positionImage: { type: String, default: null },
  animatedAnnounce: { type: Boolean, default: false },
  animation: { type: Boolean, default: true },
  isBlank: { type: Boolean, default: false },
  aspect: {
    type: Object,
    default: () => ({
      w: 16,
      h: 9,
    }),
  },
});

const emit = defineEmits(['close']);

const slots = useSlots();
const { isDesktop, isMobile } = useBreakpoint();
const { shrinkText, truncTags } = useUtils();
const getCardSettings = useCardSettings();
const getDataByModel = computed(() => getCardSettings(props.data));

const getTitle = computed(() => {
  if (props.data?.model_type === 'biography') {
    return `${props.data.surname || ''} ${props.data.firstname || ''} ${
      props.data.patronymic || ''
    }`;
  }

  if (!props.titleLength) {
    return props.data.title;
  }

  if (!props.data.title) return;

  const splitText = props.data.title.split('');
  return splitText.length > props.titleLength
    ? `${splitText.slice(0, props.titleLength).join('')}...`
    : props.data.title;
});

const hrefSlug = computed(() => {
  if (props.data?.model_type === 'document') {
    return `${props.data.category_slug}/${props.data.slug}`;
  }

  const id = props.isTestResult ? props.data.test_id : props.data.id;

  return props.data.model_type === 'test'
    ? props.data.id
    : props.data?.slug ?? id;
});

const getUrl = computed(() => {
  return (
    props.data?.outer_link ??
    props.data?.url ??
    `${getDataByModel?.value?.hrefBase}${hrefSlug.value}`
  );
});

const isSpecialProject = computed(
  () => props.data?.model_type === 'specialproject',
);

const titleTextSize = computed(() => {
  if (props.disableAnnounce) {
    return 'text-[18px] 2xl:text-[22px] 2xl:leading-[26px]';
  }
  if (props.isSmallTitle) {
    return 'text-[14px] leading-[18px] 2xl:text-[18px] 2xl:leading-[23px]';
  }
  if (props.disablePreview) {
    return 'text-new-test-black text-[20px] leading-[24px]';
  }
  return 'text-[18px] leading-[22px] 2xl:text-[24px] 2xl:leading-[34px] font-semibold';
});

const getAuthorName = computed(() => {
  const author = props.data?.authors?.[0];
  if (!author) return '';
  const name = !author.firstname ? '' : author.firstname;
  const surname = !author.surname ? '' : author.surname;

  return `${name} ${surname}`;
});
</script>

<style lang="scss">
.animated-announce-hovered {
  opacity: 0;
  height: 0;
  transform: translateY(100%);
  transition: 500ms;
  transition-delay: 150ms;
}
.animated-announce:hover .animated-announce-hovered {
  transform: translateY(0%);
  opacity: 1;
  height: initial;
}
</style>
